// Main imports
import { Routes, Route, Navigate } from "react-router-dom";

// Views and components
import Brands from "./views/Brands/Brands";
// import Footer from "./components/ui/Footer/Footer";
import Home from "./views/Home/Home";
import RoomNumber from "./views/RoomNumber/RoomNumber";


// Utilities
import "./App.css";
import { useContext } from "react";
import WebplaceContext from "./store/webplace-context";

const App = () => {
  const brandCtx = useContext(WebplaceContext).brandsInformation.brands_info
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="restaurants" element={<Brands />} />
        {brandCtx.map((path) => {
          return <Route key={path.id} path={`restaurants/${path.url}`} element={<RoomNumber />} />
        })}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      {/* <Footer /> */}
    </div>

  );
};

export default App;
