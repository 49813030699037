import { useContext, useEffect, useState } from "react";
import WebplaceContext from "../../store/webplace-context";

import HeroImg from "../../assets/mainItems/heroImg.png";
import "./RoomNumber.css";
import ButtonFormat from "../../components/shared/ButtonFormat/ButtonFormat";
import Navbar from "../../components/ui/navbar/Navbar";


import HotelRooms from "../../data/hotelRooms.json"

const RoomNumber = () => {
  const stylesCtx = useContext(WebplaceContext).brandsInformation.brands_styles;
  const [valueRoom, setValueRoom] = useState("")
  const [infoRoom, setInfoRoom] = useState({ value: 0, type: "" })
  const [paramsString, setParamsString] = useState("")
  const [showErrorMsg, setShowErrorMsg] = useState(false)
  const [isDuplicateValue, setIsDuplicateValue] = useState(false)
  const [duplicateTypes, setDuplicateTypes] = useState([])
  const [hotelType, setHotelType] = useState("")
  const [expandedSelector, setExpandedSelector] = useState(false)

  useEffect(() => {
    const fullUrl = window.location.href

    const paramIndex = fullUrl.indexOf("/restaurants")

    setParamsString(fullUrl.substring(paramIndex + "/restaurants".length))
  }, [])

  const inputNumberChange = (e) => {
    const result = e.target.value.replace(/\D/g, '');
    if (showErrorMsg) {
      setShowErrorMsg(false)
    }
    setValueRoom(result)

    // Check if input has 4 characters for duplicates
    if (e.target.value.length === 4) {
      const occurrences = {};

      // Iterate through the array
      HotelRooms.forEach(obj => {
        const { value, type } = obj;

        // DONT TOUCH THIS CODE IDK WHY EVERYTHIGN EXPLOTES IF YOU MOVE ANYTHING!
        // Check if the value (converted to string) matches the chosenValue
        if (String(value) === e.target.value) {
          // Check if the type has occurred before
          if (!occurrences[type]) {
            occurrences[type] = 1;
          }
        }
      });

      const keysArray = Object.keys(occurrences)

      if (keysArray.length > 0) {
        setIsDuplicateValue(true)
      }


      setDuplicateTypes(keysArray)
    } else {
      setDuplicateTypes([])
      setIsDuplicateValue(false)
    }

  }

  const handleRoomSearch = (e) => {
    e.preventDefault()
    setShowErrorMsg(false)


    // Find the object with the room and type
    // eslint-disable-next-line
    const roomInfo = HotelRooms.find(obj => obj.value == valueRoom)
    // Check if room exists
    // Room with length 3
    if (roomInfo !== undefined && valueRoom.length === 3) {
      setInfoRoom({ value: valueRoom, type: roomInfo.type })
      // Room with length 4 with no duplicate room numbers
    } else if (roomInfo !== undefined && !duplicateTypes) {
      setInfoRoom({ value: valueRoom, type: roomInfo.type })
      // Room with length 4 and has duplicate room numbers
    } else if (roomInfo !== undefined && duplicateTypes) {
      setInfoRoom({ value: valueRoom, type: hotelType })
      // Room not found
    } else {
      setShowErrorMsg(true)
      return null
    }
  }

  useEffect(() => {
    // Check if both value and type are truthy before redirecting
    if (infoRoom.value && infoRoom.type) {
      if (paramsString === "/the-wharf-at-sunset-walk") {
        window.location.href = `https://order.tryotter.com/s${paramsString}/3274-margaritaville-boulevard-kissimmee/c9aa2e15-600c-4f98-8bdd-b5e13f01ac68?table_number=${infoRoom.value}${infoRoom.type}${infoRoom.value.length <= 3 ? "Tower" : ""}`
      } else if (paramsString === "/lizzie's-memphis-style-bbq-room-service") {
        window.location.href = `https://order.tryotter.com/s${paramsString}/3720-margaritaville-blvd-kissimmee/44fbbf3e-8961-4442-962c-0358f040822a?table_number=${infoRoom.value}${infoRoom.type}${infoRoom.value.length <= 3 ? "Tower" : ""}`
      } else if (paramsString === "/burgerfi-at-sunset-walk") {
        window.location.href = `https://order.tryotter.com/s${paramsString}/3298-margaritaville-boulevard-kissimmee/0086f1bd-f22c-4907-8c46-e77f45892b4a?table_number=${infoRoom.value}${infoRoom.type}${infoRoom.value.length <= 3 ? "Tower" : ""}`
      } else if (paramsString === "/coal-fired-pizza-at-sunset-walk") {
        window.location.href = `https://order.tryotter.com/s/anthony%27s-coal-fired-pizza-at-sunset-walk/3298-margaritaville-boulevard-kissimmee/b5ca8a73-aec1-480b-80ec-de78f33e0292?table_number=${infoRoom.value}${infoRoom.type}${infoRoom.value.length <= 3 ? "Tower" : ""}`
      } else {
        window.location.href = `/`
      }
    }
  }, [infoRoom, paramsString]);

  const expandSelectorHandler = (e) => {
    if (e.target.value !== "DEFAULT") {
      setHotelType(e.target.value)
    }
    setExpandedSelector(!expandedSelector)
  }

  return (
    <div>
      <a href="#main" className="skip-to-main">Skip to main content</a>
      <Navbar />
      <div className="spacer"></div>
      <div className="room-number-main-info-wrapper" id="main">
        <img
          src={HeroImg}
          alt="main show food"
          className="room-number-hero-image-style"
        />
        <div
          className="room-number-title"
          style={{
            color: stylesCtx.title_text_color,
            borderBottom: `2px solid ${stylesCtx.title_border_color}`,
          }}
          role="heading" aria-level="1"
        >
          Room number
        </div>

        <div className="room-number-container-wrapper">
          <div className="room-number-subtitle">
            Please enter your room number so your order may be registered correctly.
          </div>
          <form className="input-wrapper" onSubmit={handleRoomSearch}>

            <div style={{ marginBottom: `${!showErrorMsg ? "30px" : ""}` }} className="input-inner-wrapper">
              <input placeholder="Room Number" maxLength={4} value={valueRoom} onChange={inputNumberChange} className="input-number-style" inputMode="numeric" aria-label={`Room number select`} />
              {showErrorMsg && <div className="input-error-msg">Room not found. Please check if there are any errors.</div>}
              {isDuplicateValue &&
                <select className="input-select" name="hotelType" onChange={(e) => expandSelectorHandler(e)} aria-label="Tower selector expand" aria-expanded={expandedSelector} defaultValue={"DEFAULT"}>
                  <option disabled value="DEFAULT"> -- Select an option -- </option>
                  {duplicateTypes.map((item) => {
                    return <option key={item} value={item} className="select-hotel-option" aria-label={`Tower select`}>{item}</option>
                  })}
                </select>
              }
            </div>
            <div id="button-format">
              <ButtonFormat
                btnSize="1.5"
                showArrow={true}
                arrowAtStart={true}
                arrowColor="#07AF51"
                showBorder={true}
                borderColor="#07AF51"
                backgroundColor="white"
                fontColor="black"
                capitalizeText={false}
                btnText="Enter"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RoomNumber;
