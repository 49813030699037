import { useContext } from "react";
import { NavLink } from "react-router-dom";

import favicon from "../../../assets/mainItems/favicon.svg";
import ButtonFormat from "../../shared/ButtonFormat/ButtonFormat";
import "./Navbar.css";
import WebplaceContext from "../../../store/webplace-context";

const Navbar = () => {
  const navbarCtx = useContext(WebplaceContext).navbarItems;
  const stylesCtx = useContext(WebplaceContext).navbarItems.navbar_styles;
  let logoSize;

  if (navbarCtx.logo_size === "large") {
    logoSize = "nav-fav-large";
  } else if (navbarCtx.logo_size === "medium") {
    logoSize = "nav-fav-medium";
  } else {
    logoSize = "nav-fav-small";
  }

  return (
    <div
      className="navbar-component-container"
      style={{ backgroundColor: stylesCtx.background_color }}
    >
      <div className="navbar-logo">
        <NavLink to={"/"}>
          {navbarCtx.logo_exists ? (
            <img
              src={favicon}
              className={`navbar-fav-image ${logoSize}`}
              alt={`${navbarCtx.business_name} logo`}
              tabIndex="0"
            />
          ) : (
            <div
              className="navbar-fav-title"
              style={{ color: stylesCtx.font_color }}
            >
              {navbarCtx.business_name}
            </div>
          )}
        </NavLink>
      </div>
      <nav role="navigation" aria-label="navbar buttons">
        <div className="nav-container">
          <ButtonFormat
            btnSize={stylesCtx.navbar_btn.btn_size}
            showArrow={stylesCtx.navbar_btn.show_arrow}
            arrowAtStart={stylesCtx.navbar_btn.arrow_at_start}
            arrowColor={stylesCtx.navbar_btn.arrow_color}
            showBorder={stylesCtx.navbar_btn.show_border}
            borderColor={stylesCtx.navbar_btn.border_color}
            backgroundColor={stylesCtx.navbar_btn.background_color}
            fontColor={stylesCtx.navbar_btn.font_color}
            capitalizeText={stylesCtx.navbar_btn.capitalize_text}
            btnText={navbarCtx.main_button_text}
            url={navbarCtx.main_button_redirect}
          />
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
