import "./ImageCard.css";

const ImageCard = (props) => {
  let imageUrl;

  if (props.imgFormat) {
    imageUrl = require(`../../../../assets/menuItems/menu${props.index + 1}.${props.imgFormat
      }`);
  } else {
    imageUrl = props.imageUrl;
  }

  return (
    <div className="image-card-wrapper">
      <div className="image-card-content">
        <img src={imageUrl} alt="menu-item" className="card-image" />
      </div>
    </div>
  );
};

export default ImageCard;
